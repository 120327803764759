import { createContext, useContext } from "react";
import { ThemeMode } from "../fe-ui/theme/themeMode";

export const EmbeddedContext = createContext<{
  themeMode?: ThemeMode;
  basePath?: string;
}>({
  themeMode: ThemeMode.light,
  basePath: "",
});

export const useEmbeddedContext = () => useContext(EmbeddedContext);
