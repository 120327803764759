// import dayjs, { Dayjs } from "dayjs";
import { atom, selector } from "recoil";
import router from "next/router";
import {
  CreateBookingGuestDto,
  CreateBookingMachineStateOutputDto,
  CreateBookingOptionalServiceDto,
  EnumCreateBookingGuestDtoGender,
} from "../api/api-client/service";
import { localStorageEffect } from "./effects";
import { dateRangeNightsCounterSelector } from "./search-state";
import {
  EnumOfferAdditionalServiceDtoImplAvailabilityMode,
  EnumOfferAdditionalServiceDtoImplPricingMode,
  EnumOfferAdditionalServiceDtoImplServiceType,
  OfferAdditionalServiceDtoImpl,
} from "../api/aggregation-client/service";

const defaultGuest = {
  firstName: "",
  lastName: "",
  passport: "00000000",
  dateOfBirth: "1990-01-01",
  isAdult: true,
  gender: EnumCreateBookingGuestDtoGender.Male,
};

export const adultsCounterAtom = atom<number>({
  key: "booking/adults",
  default: 2,
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (newValue) => {
        const children = await getPromise(childrenCounterAtom);
        const query = { ...router.query, adults: newValue.toString(), children: children.toString() };
        router.replace({ query }, undefined, { shallow: true });
      });
    },
  ],
});

export const childrenCounterAtom = atom<number>({
  key: "booking/children",
  default: 0,
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (newValue) => {
        const adults = await getPromise(adultsCounterAtom);
        const query = { ...router.query, adults: adults.toString(), children: newValue.toString() };
        router.replace({ query }, undefined, { shallow: true });
      });
    },
  ],
});

export const totalGuestsCounterSelector = selector({
  key: "booking/all-guests-counter",
  get({ get }) {
    const adults = get(adultsCounterAtom);
    const children = get(childrenCounterAtom);
    return children + adults;
  },
});

export const bookerAtom = atom<CreateBookingGuestDto>({
  key: "booking/booker",
  default: {
    ...defaultGuest,
    email: "",
    phone: "",
  },
});

export const bookingAllGuestsSelector = selector({
  key: "booking/all-guests",
  get({ get }) {
    const booker = get(bookerAtom);
    return [booker];
  },
});

export const bookingFormErrorAtom = atom<boolean>({
  key: "booking/form-error",
  default: false,
});

export const bookingSelectedServicesAtom = atom<OfferAdditionalServiceDtoImpl[]>({
  key: "booking/selected-services",
  default: [],
});

export const bookingSelectedServicesTotalPriceSelector = selector({
  key: "booking/total-services-price",
  get({ get }) {
    const nights = get(dateRangeNightsCounterSelector);
    const guests = get(totalGuestsCounterSelector);
    return get(bookingSelectedServicesAtom)
      .filter((s) => s.serviceType !== "included")
      .reduce((acc, service) => {
        switch (service.availabilityMode) {
          case EnumOfferAdditionalServiceDtoImplAvailabilityMode.Daily: {
            switch (service.pricingMode) {
              case EnumOfferAdditionalServiceDtoImplPricingMode.person: {
                return acc + service.price.priceWithTax * guests * nights;
              }
              case EnumOfferAdditionalServiceDtoImplPricingMode.room: {
                return acc + service.price.priceWithTax * nights;
              }
            }
          }

          // eslint-disable-next-line no-fallthrough
          case EnumOfferAdditionalServiceDtoImplAvailabilityMode.Arrival:
          case EnumOfferAdditionalServiceDtoImplAvailabilityMode.Departure: {
            switch (service.pricingMode) {
              case EnumOfferAdditionalServiceDtoImplPricingMode.person: {
                return acc + service.price.priceWithTax * guests;
              }
              case EnumOfferAdditionalServiceDtoImplPricingMode.room: {
                return acc + service.price.priceWithTax;
              }
            }
          }
        }
        return acc;
      }, 0);
  },
});

export const bookingOptionalServicesSelector = selector<CreateBookingOptionalServiceDto[]>({
  key: "booking/optional-services",
  get({ get }) {
    const guests = get(totalGuestsCounterSelector);
    return get(bookingSelectedServicesAtom)
      .filter((s) => s.serviceType !== EnumOfferAdditionalServiceDtoImplServiceType.included)
      .map((service) => {
        let count = 0;

        if (service.availabilityMode === EnumOfferAdditionalServiceDtoImplAvailabilityMode.Daily) {
          if (service.pricingMode === EnumOfferAdditionalServiceDtoImplPricingMode.person) {
            count = guests;
          } else if (service.pricingMode === EnumOfferAdditionalServiceDtoImplPricingMode.room) {
            count = 1;
          }
        } else if (
          service.availabilityMode === EnumOfferAdditionalServiceDtoImplAvailabilityMode.Arrival ||
          service.availabilityMode === EnumOfferAdditionalServiceDtoImplAvailabilityMode.Departure
        ) {
          if (service.pricingMode === EnumOfferAdditionalServiceDtoImplPricingMode.person) {
            count = guests;
          } else if (service.pricingMode === EnumOfferAdditionalServiceDtoImplPricingMode.room) {
            count = 1;
          }
        }

        return {
          count,
          additionalServiceId: service.id,
        };
      });
  },
});

/**
 * Applied promo code, pending acceptance
 */
export const bookingPromoCodeAppliedCodeAtom = atom<string | undefined>({
  key: "booking/promocode/applied-code",
  default: undefined,
});

/**
 * Successfully applied promo code
 */
export const bookingPromoCodeEffectiveCodeAtom = atom<string | undefined>({
  key: "booking/promocode/effective-code",
  default: undefined,
});

/**
 * Last promo code that failed while being applied
 */
export const bookingPromoCodeLastFailedCodeAtom = atom<string | undefined>({
  key: "booking/promocode/last-failed-code",
  default: undefined,
});

export const bookingPromoCodeIsApplyingAtom = atom<boolean>({
  key: "booking/promocode/is-applying",
  default: false,
});

export const bookingPromoCodeErrorAtom = atom<boolean>({
  key: "booking/promocode/error",
  default: false,
});

export const bookingLastCreatedBookingState = atom<CreateBookingMachineStateOutputDto | undefined>({
  key: "booking/last-created-booking-state",
  default: undefined,
  // effects: [localStorageEffect("booking/last-created-booking-state", 5 * 60 * 1000)],
});

export const bookingLastCreatedBookingTimeoutState = atom<number>({
  key: "booking/last-created-booking-timeout-state",
  default: 1800,
  // effects: [localStorageEffect("booking/last-created-booking-timeout-state", 31 * 60 * 1000)],
});

export const bookingSummaryPaymentWidgetActivationAtom = atom<boolean>({
  key: "booking/summary/is-payment-widget-activated",
  default: false,
});

export const bookingFormAgreementTermsOfUseAtom = atom<boolean>({
  key: "booking/agreement/tos",
  default: true,
});

export const bookingFormAgreementPrivacyPolicyAtom = atom<boolean>({
  key: "booking/agreement/privacy",
  default: true,
});

export const bookingAgreementsAcceptedSelector = selector({
  key: "booking/agreements-accepted",
  get({ get }) {
    return get(bookingFormAgreementTermsOfUseAtom) && get(bookingFormAgreementPrivacyPolicyAtom);
  },
});

export const bookingReferralState = atom<string>({
  key: "booking/referral",
  default: "",
  effects: [localStorageEffect("booking/referral", 30 * 24 * 60 * 60 * 1000)],
});
