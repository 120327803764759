import { TypographyOptions } from "@mui/material/styles/createTypography";
import { ThemeMode } from "./themeMode";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bodyLg: React.CSSProperties;
    bodySm: React.CSSProperties;
    bodyXl: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyLg?: React.CSSProperties;
    bodySm?: React.CSSProperties;
    bodyXl: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyLg: true;
    bodySm: true;
    bodyXl: true;
  }
}

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    "@media (max-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY_MAIN_WEBSITE = "Inter, Public Sans, sans-serif"; // Google Font
// const FONT_SECONDARY_MAIN_WEBSITE = 'CircularStd, sans-serif'; // Local Font
const FONT_PRIMARY_ATB = "Gilroy";
// NOTE: In ATB theme, for using "Publico" font for italic texts,
// specify "fontFamily: Publico" explicitly in Typography
// elements corresponding to those texts.

export default function typography(themeMode: ThemeMode): TypographyOptions {
  return {
    fontFamily: themeMode === ThemeMode.aztravel ? FONT_PRIMARY_ATB : FONT_PRIMARY_MAIN_WEBSITE,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    fontFeatureSettings: `'dlig' on, 'ss04' on, 'ss03' on, 'ss01' on, 'salt' on`,

    h1: {
      fontWeight: 600,
      lineHeight: 80 / 64,
      fontSize: pxToRem(64),
      ...responsiveFontSizes({ sm: 44, md: 52, lg: 64 }),
    },
    h2: {
      fontWeight: 600,
      lineHeight: 64 / 48,
      fontSize: pxToRem(52),
      fontVariantNumeric: "ordinal",
      fontFeatureSettings: `'cv10' on, 'cv09' on, 'cv06' on, 'cv05' on, 'cv03' on, 'cv02' on, 'cv01' on, 'cv04' on, 'cv11' on`,
      ...responsiveFontSizes({ sm: 32, md: 44, lg: 48 }),
    },
    h3: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(36),
      ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
      fontWeight: 500,
      lineHeight: 1.15,
      fontSize: pxToRem(24),
      ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(17),
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    subtitle2: {
      fontWeight: 400,
      lineHeight: 22 / 14,
      fontSize: pxToRem(18),
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    body2: {
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
    },
    bodySm: {
      fontWeight: 400,
      lineHeight: 22 / 16,
      fontSize: pxToRem(14),
    },
    bodyLg: {
      fontWeight: 400,
      lineHeight: 22 / 14,
      fontSize: pxToRem(18),
    },
    bodyXl: {
      fontWeight: 400,
      lineHeight: 22 / 14,
      fontSize: pxToRem(24),
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: "uppercase",
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      textTransform: "none",
    },
  } as TypographyOptions;
}
